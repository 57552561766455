body,
html,
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: white;
}

.App {
  font-family: "Raleway", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App .face {
  width: 30vh;
  height: 30vh;
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto;
}

.App .text {
  padding: 0 20px;
}

.App h1 {
  font-size: 250%;
  font-weight: 700;
  margin-bottom: 13px;
}

.App .subtext {
  font-size: 120%;
  font-weight: 500;
}

@media (min-width: 730px) {
  .App {
    display: flex;
    flex-direction: row;
  }
  .App .text {
    margin-left: 15px;
  }
  .App .face { width: 50vh; height: 50vh; }
}

@media (min-width: 900px) {
  .App .text {
    margin-left: 60px;
  }
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}